<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="formSubmitted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          !this.record ? $t('MF.notes.new') : $t('MF.notes.update')
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="mdNotesForm" v-model="valid">
          <v-text-field
            v-model="mdNotesForm.summary"
            outlined
            :rules="summaryRules"
            :label="$t('MF.notes.form.title')"
          />
          <!-- details input -->
          <v-textarea
            outlined
            :label="$t('MF.notes.form.details')"
            auto-grow
            v-model="mdNotesForm.note_body"
            :rules="detailsRules"
            :counter="50"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="formSubmitted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{$t('APPOINTMENTS.add.booking')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <v-form ref="mdNotesForm" v-model="valid">
      <div class="row">
        <!-- summary input -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-text-field
            v-model="mdNotesForm.summary"
            :label="$t('MF.notes.form.title')"
            :placeholder="$t('MF.notes.form.title')"
            outlined
            :rules="summaryRules"
          />
        </div>
        <!-- details input -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-textarea
            outlined
            :label="$t('MF.notes.form.details')"
            auto-grow
            v-model="mdNotesForm.note_body"
            :rules="detailsRules"
            :counter="50"
          ></v-textarea>
        </div>
      </div>
      <v-btn color="primary mb-3" @click="submit" :disabled="!valid"
        >{{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}</v-btn
      >
    </v-form>
    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'HistoryForm',
  props: ['mfid', 'dialog', 'record'],
  data() {
    return {
      error: false,
      errorMsg: '',
      itemTypes: [],
      itemTypesLoading: true,
      selectedType: '',
      menu2: false,
      valid: false,
      formSubmitted: false,
      secondOpinion: false,
      mdNotesForm: {
        summary: '',
        note_body: '',
      },
      summaryRules: [
        (v) => !!v || this.$t('MF.notes.validation.title'),
      ],
      detailsRules: [
        (v) => !!v || this.$t('MF.notes.validation.details'),
        (v) =>
          (v && v.trim().length >= 10) ||
          this.$t('MF.notes.validation.length'),
      ],
    };
  },
  async mounted() {
    this.getItemTypes();
    this.record &&
      Object.assign(this.mdNotesForm, {
        summary: this.record['summary'],
        note_body: this.record['note_body'],
      });
    console.log('from notes', this.record);
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.mdNotesForm.validate()) {
          // const payload = {
          //   summary: this.mdNotesForm.summary,
          //   note_body: this.mdNotesForm.details,
          //   medical_file: this.mfid,
          // };
          this.formSubmitted = true;
          if (this.record) {
            await ApiService.update(
              'items/md_notes',
              this.record.id,
              this.mdNotesForm
            );
          } else {
            this.mdNotesForm['medical_file'] = this.mfid;
            await ApiService.post(
              'items/md_notes?fields=created_on',
              this.mdNotesForm
            );
          }

          this.formSubmitted = false;
          this.$refs.mdNotesForm.reset();

          this.$emit(
            'backToStepOneAndToast',
            this.record
              ? 'Note Added Successfully'
              : 'Note Updated Successfully'
          );
        } else {
          this.errorMsg = this.$t("MF.notes.error");
          this.error = true;
        }
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },

    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          'items',
          'history_type?fields=id,type,translation.*'
        );
        this.itemTypes = data.data;
        this.itemTypesLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit('closeClicked', false);
    },
  },
};
</script>
