<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="askStarted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.consultation.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="consultationForm" v-model="valid">
          <v-autocomplete
            v-model="department"
            clearable
            outlined
            :items="departments"
            :loading="loadingStatus"
            :filter="departmentFilter"
            :label="$t('MF.consultation.form.department')"
            item-text="name"
            item-value="name"
            return-object
            @change="getDoctors"
            :rules="[v => !!v || $t('MF.consultation.validation.department')]"
          >
            <template v-slot:selection="data">
              {{ getTranslation(data.item).name }}
            </template>
            <template v-slot:item="data">
              {{ getTranslation(data.item).name }}
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="doctor"
            clearable
            outlined
            :items="doctors"
            :loading="loadingStatus"
            :filter="doctorFilter"
            :label="$t('MF.consultation.form.select')"
            item-text="doctor_id.first_name"
            item-value="doctor_id.first_name"
            return-object
            :rules="[v => !!v || $t('MF.consultation.validation.select')]"
          >
            <template v-slot:selection="data">
              {{ data.item.doctor_id.first_name }}
              {{ data.item.doctor_id.last_name }}
            </template>
            <template v-slot:item="data">
              {{ data.item.doctor_id.first_name }}
              {{ data.item.doctor_id.last_name }}
            </template>
          </v-autocomplete>

          <v-textarea
            outlined
            :label="$t('MF.consultation.form.details')"
            auto-grow
            v-model="details"
            :rules="detailsRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t("MF.consultation.cancel") }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t("MF.consultation.send") }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="askStarted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t("APPOINTMENTS.add.booking") }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <v-form ref="consultationForm" v-model="valid">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-autocomplete
            v-model="department"
            clearable
            outlined
            :items="departments"
            :loading="loadingStatus"
            :filter="departmentFilter"
            :label="$t('MF.consultation.form.department')"
            item-text="name"
            item-value="name"
            return-object
            @change="getDoctors"
            :rules="[v => !!v || $t('MF.consultation.validation.department')]"
          >
            <template v-slot:selection="data">
              {{ getTranslation(data.item).name }}
            </template>
            <template v-slot:item="data">
              {{ getTranslation(data.item).name }}
            </template>
          </v-autocomplete>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-autocomplete
            v-model="doctor"
            clearable
            outlined
            :items="doctors"
            :loading="loadingStatus"
            :filter="doctorFilter"
            :label="$t('MF.consultation.form.select')"
            item-text="doctor_id.first_name"
            item-value="doctor_id.first_name"
            return-object
            :rules="[v => !!v || $t('MF.consultation.validation.select')]"
          >
            <template v-slot:selection="data">
              {{ data.item.doctor_id.first_name }}
              {{ data.item.doctor_id.last_name }}
            </template>
            <template v-slot:item="data">
              {{ data.item.doctor_id.first_name }}
              {{ data.item.doctor_id.last_name }}
            </template>
          </v-autocomplete>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-textarea
            outlined
            :label="$t('MF.consultation.form.details')"
            auto-grow
            v-model="details"
            :rules="detailsRules"
          ></v-textarea>
        </div>
      </div>
      <v-btn color="primary mb-3" @click="submit" :disabled="!valid"
        >{{ $t("MF.consultation.send") }}</v-btn
      >
    </v-form>

    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t("MF.consultation.cancel") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "HistoryForm",
  props: ["mfid", "dialog"],
  data() {
    return {
      error: false,
      errorMsg: "err",
      // itemTypes: [],
      departments: [],
      department: null,
      doctors: [],
      loadingStatus: true,
      selectedType: "",
      valid: false,
      askStarted: false,
      doctor: null,
      details: "",
      detailsRules: [
        v => !!v || this.$t('MF.consultation.validation.Details'),
        v =>
          (v && v.trim().length >= 10) ||
          this.$t('MF.consultation.validation.length')
      ]
    };
  },
  mounted() {
    // this.getItemTypes();
    this.getDepartments();
    // this.getDoctors();
  },
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async submit() {
      try {
        if (this.$refs.consultationForm.validate()) {
          const consultationForm = {
            recipient: this.doctor.id,
            reason: this.details,
            medical_file: this.mfid
          };

          this.askStarted = true;
          await ApiService.post(
            "items/referral?fields=created_on",
            consultationForm
          );
          this.askStarted = false;
          this.$refs.consultationForm.reset();
          this.$emit(
            "backToStepOneAndToast",
            "Added Medicla History Successfully"
          );
        } else {
          this.errorMsg = this.$t('MF.consultation.error');
          this.error = true;
        }
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },
    // async getItemTypes() {
    //   try {
    //     const { data } = await ApiService.get(
    //       'items',
    //       'history_type?fields=id,type,translation.*'
    //     );
    //     this.itemTypes = data.data;
    //     this.itemTypesLoading = false;
    //   } catch (error) {
    //     this.errorMsg = error;
    //     this.error = true;
    //   }
    // },
    async getDepartments() {
      try {
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          "items",
          "department?fields=id,name,translation.*"
        );
        this.departments = data.data;
        console.log(this.departments);
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        this.error = error;
        console.log(error);
      }
    },

    async getDoctors() {
      try {
        if (!this.department) {
          return;
        }
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          "items",
          "doctor?fields=id,doctor_id.id,doctor_id.first_name,doctor_id.last_name,doctor_id.avatar.data,doctor_id.email,department,video,clinic,bio,practice_started&filter[department]=" +
            this.department.id
        );
        console.log("dosctors", data.data);
        this.doctors = data.data;
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        this.error = error;
        console.log(error);
      }
    },

    departmentFilter(department, queryText) {
      return department.toLowerCase().includes(queryText.toLowerCase());
    },

    doctorFilter(doctor, queryText) {
      return doctor.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeClicked", false);
    }
  }
};
</script>
