<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="formSubmitted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          !this.record ? $t('MF.vital.new') : $t('MF.vital.update')
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="mdVitalsForm" v-model="valid">
          <v-autocomplete
            v-model="mdVitalsForm.vital_type"
            clearable
            outlined
            :items="itemTypes"
            :loading="itemTypesLoaded"
            :filter="typeFilter"
            :label="$t('MF.vital.form.type')"
            item-text="name"
            item-value="id"
            :rules="[v => !!v || $t('MF.vital.validation.type')]"
          >
            <template v-slot:selection="data">
              {{ getTranslation(data.item).name }}
            </template>
            <template v-slot:item="data">
              {{ getTranslation(data.item).name }}
            </template>
          </v-autocomplete>

          <v-text-field
            type="number"
            outlined
            :label="$t('MF.vital.form.reading')"
            :suffix="getSuffix()"
            v-model="mdVitalsForm.reading"
            :rules="[v => !!v || $t('MF.vital.validation.reading')]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="formSubmitted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{$t('APPOINTMENTS.add.booking')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <v-form ref="mdVitalsForm" v-model="valid">
      <div class="row">
        <!-- vital type -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-autocomplete
            v-model="mdVitalsForm.vital_type"
            clearable
            outlined
            :items="itemTypes"
            :loading="itemTypesLoaded"
            :filter="typeFilter"
            :label="$t('MF.vital.form.type')"
            item-text="name"
            item-value="id"
            :rules="[v => !!v || $t('MF.vital.validation.type')]"
          >
            <template v-slot:selection="data">
              {{ getTranslation(data.item).name }}
            </template>
            <template v-slot:item="data">
              {{ getTranslation(data.item).name }}
            </template>
          </v-autocomplete>
        </div>
        <!-- reading -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-text-field
            type="number"
            outlined
            :label="$t('MF.vital.form.reading')"
            :suffix="getSuffix()"
            v-model="mdVitalsForm.reading"
            :rules="[v => !!v || $t('MF.vital.validation.reading')]"
          ></v-text-field>
        </div>
      </div>
      <v-btn color="primary mb-3" @click="submit" :disabled="!valid"
        >{{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}</v-btn
      >
    </v-form>
    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'VitalsForm',
  props: ['mfid', 'dialog', 'record'],
  data() {
    return {
      error: false,
      errorMsg: '',
      method: null,
      valid: false,
      formSubmitted: false,
      itemTypes: [],
      itemTypesLoaded: true,
      menu2: false,
      mdVitalsForm: {
        reading: '',
        vital_type: '',
      },
    };
  },
  mounted() {
    this.getItemTypes();
    this.record &&
      Object.assign(this.mdVitalsForm, {
        reading: this.record['reading'],
        vital_type: this.record['vital_type'],
      });
  },
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          'items',
          'vital_types?fields=id,name,suffix,translation.*'
        );
        this.itemTypes = data.data;
        this.itemTypesLoaded = false;
      } catch (error) {
        console.log(error);
        this.error = error;
      }
    },
    typeFilter(item, queryText) {
      return item.name.toLowerCase().includes(queryText.toLowerCase());
    },
    getSuffix() {
      if (this.mdVitalsForm.vital_type.suffix) {
        return this.mdVitalsForm.vital_type.suffix;
      } else {
        return '';
      }
    },
    async submit() {
      try {
        if (this.$refs.mdVitalsForm.validate()) {
          this.formSubmitted = true;

          if (this.record) {
            await ApiService.update(
              'items/md_vitals',
              this.record.id,
              this.mdVitalsForm
            );
          } else {
            this.mdVitalsForm['medical_file'] = this.mfid;
            this.mdVitalsForm['method'] = 'manual';
            await ApiService.post(
              'items/md_vitals?fields=created_on',
              this.mdVitalsForm
            );
          }

          this.formSubmitted = false;

          this.$refs.mdVitalsForm.reset();
          this.$emit(
            'backToStepOneAndToast',
            this.record
              ? 'VitalAdded Successfully'
              : 'VitalUpdated Successfully'
          );
        } else {
          this.errorMSg = this.$t("MF.vital.error");
          this.error = true;
        }
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },
    close() {
      this.$emit('closeClicked');
    },
  },
  computed: {}
};
</script>
