<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="formSubmitted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          !this.record ? $t('MF.history.new') : $t('MF.history.update')
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="mdHistoryForm" v-model="valid">
          <v-autocomplete
            v-model="mdHistoryForm.history_type"
            clearable
            outlined
            :items="itemTypes"
            :loading="itemTypesLoading"
            :filter="typeFilter"
            :label="$t('MF.history.form.type')"
            item-text="type"
            item-value="id"
            :rules="[v => !!v || $t('MF.history.validation.type')]"
          >
            <template v-slot:selection="data">
              {{ getTranslation(data.item).name }}
            </template>
            <template v-slot:item="data">
              {{ getTranslation(data.item).name }}
            </template>
          </v-autocomplete>

          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="mdHistoryForm.incident_date"
                :label="$t('MF.history.form.date')"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || $t('MF.history.validation.type')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="mdHistoryForm.incident_date"
              @input="menu2 = false"
            />
          </v-menu>

          <v-textarea
            outlined
            :label="$t('MF.history.form.details')"
            auto-grow
            v-model="mdHistoryForm.history_body"
            :rules="detailsRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="formSubmitted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{$t('APPOINTMENTS.add.booking')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <v-form ref="mdHistoryForm" v-model="valid">
      <div class="row">
        <!-- md history type input  -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-autocomplete
            v-model="mdHistoryForm.history_type"
            clearable
            outlined
            :items="itemTypes"
            :loading="itemTypesLoading"
            :filter="typeFilter"
            :label="$t('MF.history.form.type')"
            item-text="type"
            item-value="id"
            :rules="[v => !!v || $t('MF.history.validation.type')]"
          >
            <template v-slot:selection="data">
              {{ data.item.type }}
            </template>
            <template v-slot:item="data">
              {{ data.item.type }}
            </template>
          </v-autocomplete>
        </div>
        <!-- incident date input -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="mdHistoryForm.incident_date"
                :label="$t('MF.history.form.date')"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || $t('MF.history.validation.date')]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="mdHistoryForm.incident_date"
              @input="menu2 = false"
            />
          </v-menu>
        </div>
        <!-- details input -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <v-textarea
            outlined
            :label="$t('MF.history.form.details')"
            auto-grow
            v-model="mdHistoryForm.history_body"
            :rules="detailsRules"
          ></v-textarea>
        </div>
      </div>
      <v-btn color="primary mb-3" @click="submit" :disabled="!valid"
        >{{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}</v-btn
      >
    </v-form>

    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "HistoryForm",
  props: ["mfid", "dialog", "record"],
  data() {
    return {
      error: false,
      errorMsg: "err",
      itemTypes: [],
      itemTypesLoading: true,
      selectedType: "",
      menu2: false,
      valid: false,
      formSubmitted: false,
      mdHistoryForm: {
        history_type: '',
        incident_date: '',
        history_body: '',
      },
      detailsRules: [
        v => !!v || this.$t('MF.history.validation.details'),
        v =>
          (v && v.trim().length >= 10) ||
          this.$t('MF.history.validation.details_length')
      ]
    };
  },
  mounted() {
    this.getItemTypes();
    this.record &&
      Object.assign(this.mdHistoryForm, {
        history_type: this.record['history_type'],
        history_body: this.record['history_body'],
        incident_date: this.record['incident_date'],
      });
  },
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async submit() {
      try {
        if (this.$refs.mdHistoryForm.validate()) {
          this.formSubmitted = true;
          if (this.record) {
            await ApiService.update(
              'items/md_history',
              this.record.id,
              this.mdHistoryForm
            );
          } else {
            this.mdHistoryForm['medical_file'] = this.mfid;
            await ApiService.post(
              'items/md_history?fields=created_on',
              this.mdHistoryForm
            );
          }

          this.formSubmitted = false;
          this.$refs.mdHistoryForm.reset();

          this.$emit(
            'backToStepOneAndToast',
            this.record
              ? 'Medicla History Added Successfully'
              : 'Medicla History Updated Successfully'
          );
        } else {
          this.errorMsg = this.$t('MF.history.error');
          this.error = true;
        }
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "history_type?fields=id,type,translation.*,translation.*"
        );
        console.log("type", data.data)
        this.itemTypes = data.data;
        this.itemTypesLoading = false;
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },
    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit('closeClicked');
    },
  },
};
</script>
