<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="askStarted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          !this.record ? $t('MF.files.new') : $t('MF.files.update')
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="filesForm" v-model="valid">
          <v-file-input
            v-model="files"
            color="primary"
            counter
            :label="$t('MF.files.form.title')"
            multiple
            :placeholder="$t('MF.files.form.details')"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
            :rules="[v => !!v || $t('MF.files.validation.files')]"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 4" color="primary" dark label small>
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 4"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 4 }} {{$t('MF.files.labels.file')}}
              </span>
            </template>
          </v-file-input>
          <v-simple-table v-if="files.length > 0" class="mb-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width:40%" class="text-left">{{$t('MF.files.labels.filename')}}</th>
                  <th style="width:50%" class="text-left">{{$t('MF.files.labels.upload')}}</th>
                  <th style="width:10%" class="text-left">{{$t('MF.files.labels.delete')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(file, index) in files" :key="index">
                  <td>{{ file.name }}</td>
                  <td>
                    <v-progress-linear
                      color="primary"
                      :value="uploadProgress"
                      height="6"
                      v-if="myturn.length <= files.length && myturn[index] == 1"
                    ></v-progress-linear>
                    <span style="display: none">{{ uploadProgress }}</span>
                    <span v-if="myturn[index] == 2">{{$t('MF.files.labels.done')}}</span>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        :disabled="!enableDelete"
                        class="btn btn-sm btn-icon btn-light-danger mr-2"
                        @click="removeFile(file)"
                      >
                        <em class="far fa-trash-alt"></em>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer />
        <v-btn text color=" primary" @click="close()">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="primary" @click="submit" :disabled="!valid">
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="askStarted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{$t('APPOINTMENTS.add.booking')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" v-else>
    <div class="row mt-0">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <v-form ref="filesForm" v-model="valid">
          <v-file-input
            v-model="files"
            color="primary"
            counter
            :label="$t('MF.files.form.title')"
            multiple
            :placeholder="$t('MF.files.form.details')"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
            :rules="[v => !!v || $t('MF.files.validation.files')]"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 4" color="primary" dark label small>
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 4"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 4 }} {{$t('MF.files.labels.file')}}
              </span>
            </template>
          </v-file-input>
          <v-simple-table v-if="files.length > 0" class="mb-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width:40%" class="text-left">{{$t('MF.files.labels.filename')}}</th>
                  <th style="width:50%" class="text-left">{{$t('MF.files.labels.upload')}}</th>
                  <th style="width:10%" class="text-left">{{$t('MF.files.labels.delete')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(file, index) in files" :key="index">
                  <td>{{ file.name }}</td>
                  <td>
                    <v-progress-linear
                      color="primary"
                      :value="uploadProgress"
                      height="6"
                      v-if="myturn.length <= files.length && myturn[index] == 1"
                    ></v-progress-linear>
                    <span style="display: none">{{ uploadProgress }}</span>
                    <span v-if="myturn[index] == 2">{{$t('MF.files.labels.done')}}</span>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        :disabled="!enableDelete"
                        class="btn btn-sm btn-icon btn-light-danger mr-2"
                        @click="removeFile(file)"
                      >
                        <em class="far fa-trash-alt"></em>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn color="primary mb-3" @click="submit" :disabled="!valid"
            >{{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}</v-btn
          >
        </v-form>
      </div>
    </div>

    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "FilesForm",
  props: ["mfid", "dialog"],
  data() {
    return {
      error: false,
      errorMsg: "",
      valid: false,
      files: [],
      uploadProgress: 0,
      myturn: [],
      enableDelete: true,
      askStarted: false
    };
  },
  mounted() {},
  methods: {
    backToStepOneAndToast() {
      this.enableDelete = true;
      this.files = [];
      this.uploadProgress = 0;
      this.myturn = [];
      this.$emit("backToStepOneAndToast", "Added Files Successfully");
    },
    uploadProgressCallBack(index, progress) {
      console.log("in progress and it is: ", progress);
      console.log("target is:", this.myturn[index]);
      this.uploadProgress = progress;
    },
    async submit() {
      try {
        if (this.$refs.filesForm.validate()) {
          this.enableDelete = false;
          for (var i = 0; i < this.files.length; i++) {
            var formData = new FormData();
            formData.append("data", this.files[i]);

            this.myturn[i] = 1;

            this.askStarted = true;
            const { data } = await ApiService.postFile(
              "files",
              formData,
              i,
              this.uploadProgressCallBack
            );

            await ApiService.post("items/medical_file_directus_files", {
              medical_file_id: this.mfid,
              directus_files_id: data.data.id
            });
            this.myturn[i] = 2;
            this.askStarted = false;

            this.backToStepOneAndToast();
          }
        } else {
          this.errorMsg = this.$t("MF.files.error");
          this.error = true;
        }
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
        console.log(error);
      }
    },
    removeFile(f) {
      const index = this.files.indexOf(f);
      if (index >= 0) this.files.splice(index, 1);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    close() {
      this.$emit("closeClicked", false);
    }
  },
  computed: {},
  watch: {
    /*files: function (newFiles) {
      for(var i = 0; i < newFiles.length; i++)
      {
        this.uploadProgress[i] = 0;
      }
    }*/
  }
};
</script>
